import React, {
  createContext,
  useReducer,
  useContext,
  useEffect,
  Dispatch,
} from "react";
import moment from "moment";
import { User, auth, getUser, refresh as refreshUser } from "../api/auth";
import { SubmissionError } from "redux-form";

import { resetDataLoader } from "../data/DataLoader";
import { resetDatabase } from "../data/Database";
import { Moment } from "moment-timezone";
import { Client } from "../api/clients";

export interface UserState {
  id: number | null;
  name: string | null;
  email: string | null;
  isAdmin: boolean;
  isBasic: boolean;
  client: Client;
  token: string | null;
  tokenExpiry: Moment | null;
  settings: User["settings"];
  adminAccess: number[];
  isSomewhatAdmin: boolean;
}

const defaultState: UserState = {
  id: null,
  name: null,
  email: null,
  isAdmin: false,
  isSomewhatAdmin: false,
  isBasic: false,
  client: { id: null },
  token: null,
  tokenExpiry: null,
  settings: [],
  adminAccess: [],
};

const AuthStateContext = createContext(defaultState);

function settingsReducer(
  state: UserState,
  action: { type: "setUser"; payload: Partial<UserState> }
) {
  switch (action.type) {
    case "setUser": {
      const newState = { ...state, ...action.payload };
      newState.isSomewhatAdmin =
        newState.isAdmin || newState.adminAccess.length > 0;
      return newState;
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

type AuthDispatch = Dispatch<{
  type: "setUser";
  payload: Partial<UserState>;
}>;

const AuthDispatchContext = createContext<AuthDispatch>(() => null);

export const AuthProvider: React.FC = ({ children }) => {
  const localNode = "AgriTechUser";
  const local = localStorage.getItem(localNode);
  const initialState = (local && JSON.parse(local)) || defaultState;
  const [state, dispatch] = useReducer(settingsReducer, initialState);

  useEffect(() => {
    localStorage.setItem(localNode, JSON.stringify(state));
  }, [state]);

  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
};

export function useAuthState() {
  const context = useContext(AuthStateContext);
  if (context === undefined) {
    throw new Error("useAuthState must be used within a AuthProvider");
  }
  return context;
}

export function useAuthDispatch() {
  const context = useContext(AuthDispatchContext);
  if (context === undefined) {
    throw new Error("useAuthDispatch must be used within a AuthProvider");
  }
  return context;
}

export function usePermissions() {
  const { client } = useAuthState();
  return client.module_access
    ? client.module_access.map((module) => module.module_id)
    : [];
}

export function useHasAccess(id: number) {
  const permissions = usePermissions();
  return permissions.includes(id);
}

export async function login(
  dispatch: AuthDispatch,
  credentials: { email: string; password: string }
) {
  try {
    const authenticate = await auth(credentials);
    const {
      data: { token, expires_in },
    } = authenticate;
    // request user details
    try {
      if (Storage !== undefined) {
        localStorage.clear();
      }

      const response = await getUser(token);
      const {
        data: { client, user },
      } = response;

      dispatch({
        type: "setUser",
        payload: {
          id: user.id,
          name: user.name,
          email: user.email,
          isAdmin: user.admin === 1,
          isBasic: user.basic === 1,
          client,
          token,
          tokenExpiry: moment().add(expires_in, "seconds"),
          settings: user.settings,
          adminAccess: user.admin_access.map((a) => a.client_id),
        },
      });

      resetDatabase();
      resetDataLoader();

      return true;
    } catch (e: any) {
      throw new SubmissionError({
        _error: `Unable to get user details: ${e.response.data.message}`,
      });
    }
  } catch (e: any) {
    const message =
      e.response === undefined ? e.message : e.response.data.message;
    throw new SubmissionError({
      _error: `Login failed: ${message}`,
    });
  }
}

export async function refresh(
  dispatch: AuthDispatch,
  { token, tokenExpiry }: { token: string; tokenExpiry: Moment | null }
) {
  // if auth token is expired
  if (tokenExpiry === null || moment(tokenExpiry).isBefore(moment())) {
    logout(dispatch);
  }

  const expiryLimit = 60 * 60 * 24 * 1; // 1 day;
  // if token expires within 1 day
  if (moment(tokenExpiry).diff(moment(), "s") < expiryLimit) {
    // attempt to refresh token
    try {
      const response = await refreshUser(token);
      // update the user token & expiry
      dispatch({
        type: "setUser",
        payload: {
          token: response.data.token,
          tokenExpiry: moment().add(response.data.expires_in, "seconds"),
        },
      });
    } catch (e: any) {
      // if network error
      if (
        e.response === undefined ||
        e.response.data.message === "Token is Expired"
      ) {
        // display login modal
        return logout(dispatch);
      }
    }
  }
}

export async function logout(dispatch: AuthDispatch) {
  resetDatabase();
  resetDataLoader();

  if (Storage !== undefined) {
    localStorage.clear();
  }

  dispatch({ type: "setUser", payload: defaultState });
}
