import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useDispatch } from "react-redux";
import { submit } from "redux-form";
import { useAuthState } from "../../../context/auth";
import {
  getUser,
  saveUser,
  updateUser,
  setUserAdminAccess,
} from "../../../api/users";
import Card from "../../cards/Card";
import { handleRequestError, triggerToast } from "../../../helpers";
import Loader from "../../elements/Loader";
import Headline from "../../elements/Headline";
import FormButton from "../../forms/FormButton";
import { useHistory } from "react-router-dom";
import { useStyles } from "./Admin";
import UserForm from "../../forms/admin/UserForm";
import { Client, listClients } from "../../../api/clients";
import { User } from "../../../api/auth";

const ManageUser: React.FC<{
  id: number;
  setIsLoading: (loading: boolean) => void;
}> = ({ id, setIsLoading }) => {
  const classes = useStyles();
  const user = useAuthState();
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  // const [valid, setValid] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [activeUser, setActiveUser] = useState<User & { client_id: number }>();
  const [clients, setClients] = useState<Client[]>([]);
  const [fullAdmin, setFullAdmin] = useState(false);
  const [adminAccess, setAdminAccess] = useState<Map<number, boolean>>();

  useEffect(() => {
    setIsLoading(loading);
  }, [setIsLoading, loading]);

  const loadUser = useCallback(async () => {
    if (!user.token || !id) return;
    setLoading(true);
    try {
      const response = await getUser(user.token, id);
      setActiveUser(response.data);
      setLoading(false);
    } catch (e) {
      handleRequestError(e, "Failed fetching user: ");
      setLoading(false);
    }
  }, [user.token, id]);

  const loadClients = useCallback(async () => {
    if (!user.token) return;
    setLoading(true);
    try {
      const response = await listClients(user.token);
      setClients(response.data);
      setLoading(false);
    } catch (e) {
      handleRequestError(e, "Failed fetching clients: ");
      setLoading(false);
    }
  }, [user.token]);

  useEffect(() => {
    loadClients();
    loadUser();
  }, [loadUser, loadClients]);

  useEffect(() => {
    setFullAdmin(activeUser?.admin === 1);
    const adminAccess = new Map<number, boolean>();
    if (activeUser?.admin_access) {
      for (const access of activeUser.admin_access) {
        adminAccess.set(access.client_id, true);
      }
    }
    setAdminAccess(adminAccess);
  }, [activeUser]);

  const clientsOptions = useMemo(() => {
    const res: Record<number, string | undefined> = {};
    for (const client of clients) {
      if (client.id !== null) {
        res[client.id] = client.name;
      }
    }
    return res;
  }, [clients]);

  const handleSubmit = async (formValues: User & { client_id: string }) => {
    if (!user.token) return;
    setWaiting(true);
    try {
      const values: User & { client_id: number } = {
        ...formValues,
        client_id: parseInt(formValues.client_id),
        admin: fullAdmin ? 1 : 0,
      };
      const response = id
        ? await updateUser(user.token, id, values)
        : await saveUser(user.token, values);
      const adminIds: number[] = [];
      for (const [key, value] of adminAccess?.entries() || []) {
        if (value) {
          adminIds.push(key);
        }
      }
      const userId = id ?? response.data.id ?? response.data.user.id;

      await setUserAdminAccess(user.token, userId, adminIds);
      dispatch(triggerToast(response.message, { variant: "success" }));
      setWaiting(false);
      history.push("/admin");
    } catch (e) {
      setWaiting(false);
      handleRequestError(e, `Failed to ${id ? "update" : "save"} user: `);
    }
  };

  return (
    <Loader active={loading} showChildren={false} message={`Loading User...`}>
      <Headline headline={`Edit User`} headlineVariant="h1" divider={true}>
        <FormButton
          text={id ? "Update" : "Save"}
          handleClick={() => dispatch(submit("UserForm"))}
          variant="contained"
          color="primary"
          disabled={waiting}
          waiting={waiting}
          waitingText={id ? "Updating" : "Saving"}
        />
      </Headline>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={6}>
          <Grid container>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                color="textSecondary"
                className={classes.subTitle}
              >
                DETAILS:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Card>
                {clients && (
                  <UserForm
                    // @ts-ignore
                    clients={clientsOptions}
                    initialValues={activeUser}
                    // isValid={(status: boolean) => setValid(status)}
                    isWaiting={(status: boolean) => setWaiting(status)}
                    onSubmit={handleSubmit}
                  />
                )}
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Grid container>
            <Grid item xs={12} style={{ marginTop: 20 }}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>Admin Access</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Card>
                    <FormControlLabel
                      label="Full Admin"
                      control={
                        <Checkbox
                          checked={fullAdmin}
                          onChange={(_, v) => setFullAdmin(v)}
                          name={"admin"}
                          color="primary"
                        />
                      }
                    />
                    <hr />
                    {!fullAdmin &&
                      clients.map((client) => {
                        return (
                          <div key={client.id}>
                            <FormControlLabel
                              label={client.name}
                              control={
                                <Checkbox
                                  checked={
                                    !!(client.id && adminAccess?.get(client.id))
                                  }
                                  onChange={(e, v) => {
                                    if (!adminAccess || !client.id) return;
                                    adminAccess.set(client.id, v);
                                    setAdminAccess(new Map(adminAccess));
                                  }}
                                  name={"admin"}
                                  color="primary"
                                />
                              }
                            />
                          </div>
                        );
                      })}
                  </Card>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Loader>
  );
};

export default ManageUser;
