import React, { useMemo } from "react";
import moment from "moment";
import Chart from "./Chart";
import config from "../../constants/charts";
import { defaultChartOptions, rgbToRgba } from "../../helpers";
import { useMetricsState } from "../../context/metrics";

import { getChartConfig } from "../../helpers/getChartConfig";
import { useChartData } from "../../data/useChartData";
import { getPercentageInThresholds } from "./thresholds";

export const thresholdConfig = (label, threshold, color, fill, range) => ({
  label: label,
  type: "line",
  data: Object.values(range).map((time) => ({
    x: moment(time),
    y: threshold,
  })),
  fill: fill,
  borderWidth: 2,
  backgroundColor: rgbToRgba(color.light, 0.6),
  pointBackgroundColor: rgbToRgba(color.light, 0.6),
  borderColor: rgbToRgba(color.main, 1),
  pointBorderColor: rgbToRgba(color.main, 1),
  pointRadius: 0,
  borderDash: [10, 5],
});

const ChartAdvisoryLine = ({
  chart,
  fieldId,
  chartEvents,
  range,
  theme,
  thresholds,
  tooltip,
}) => {
  const { chartData } = useChartData(chart.group, range, fieldId);

  const metrics = useMetricsState();
  const chartConfig = useMemo(() => {
    const initialConfig = getChartConfig(chartData, chart.id, theme, metrics);
    const advisoryLines = Object.values(thresholds).map(
      ({ label, value, color, fill }) =>
        thresholdConfig(label, value, color, fill, range)
    );
    return {
      ...initialConfig,
      datasets: [...initialConfig.datasets, ...advisoryLines],
    };
  }, [chartData, theme, chart.id, thresholds, metrics]);

  const thresholdInfo = useMemo(() => {
    return getPercentageInThresholds(chartData ?? [], thresholds, "vpd");
  }, [chartData, thresholds]);

  const chartOptions = useMemo(() => {
    const options = defaultChartOptions(
      config[chart.id].axis,
      config[chart.id].legend,
      range,
      chartEvents
    );

    return {
      ...options,

      plugins: {
        ...options.plugins,
        legend: {
          display: true,
          position: "bottom",
          labels: {
            generateLabels: () => {
              return thresholdInfo.map(([threshold, percentage]) => ({
                text: `${threshold.label}: ${percentage.toFixed(0)}%`,
                fillStyle: threshold.color.light,
                strokeStyle: threshold.color.main,
                lineWidth: 2,
              }));
            },
          },
        },
        tooltip: {
          ...options.tooltip,
          callbacks: {
            label: (tooltipItem) => {
              return (
                !tooltipItem.datasetIndex &&
                tooltip + ": " + tooltipItem.formattedValue
              );
            },
          },
        },
      },
    };
  }, [chart.id, range, tooltip, thresholdInfo]);

  return (
    <Chart
      chartRef={chartEvents.chartRef}
      data={chartConfig}
      options={chartOptions}
    />
  );
};

export default ChartAdvisoryLine;
